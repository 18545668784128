import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Error Rporting
// Import bugsnag as the first user defined module
import 'js/lib/bugsnag'

// polyfills
import 'js/lib/polyfills'

// Import Styles
import 'sass/application'

// Base
import 'js/base'

// Shared
import 'js/shared/petrescue.init'
import 'js/shared/reverse.jquery'
import 'js/shared/postcode_live_search'
import 'js/shared/user'
import 'js/shared/select2'
import 'js/shared/uniform'
import 'js/shared/jquery_wait'
import 'js/shared/pagination'
import 'js/shared/disable_method_links'
import 'js/shared/detect_ad_block'
import 'js/shared/filterbox'
import 'js/shared/sidebar'
import 'js/shared/navigation'
import 'js/shared/image_upload_preview'
import 'js/shared/mailcheck'
import 'js/shared/jquery.expandable'
import 'js/shared/pr_expandable_init'
import 'js/shared/confetti'
import 'js/shared/adoption_notification_modal'
import 'js/shared/on_hold_notification_modal'
import 'js/shared/show_password'
import 'js/shared/password'
import 'js/shared/password_strength_indicator'
import 'js/shared/human_file_size'
import 'js/shared/external_link_warning'
import 'js/shared/serialize_object.jquery'
import 'js/shared/two_factor_auth'
import 'js/shared/controlled_by'
import 'js/shared/sticky_ad'

// Cards
import 'js/cards/common/subscribe_newsletter'

// Admin
import 'js/admin/article_search'
import 'js/admin/blog_posts'
import 'js/admin/bulk_listings_status_update'
import 'js/admin/content_replace'
import 'js/admin/group'
import 'js/admin/group_details_update_prompt'
import 'js/admin/listing_form'
import 'js/admin/listings'
import 'js/admin/logs'
import 'js/admin/tools'
import 'js/admin/tools/enquiry_blacklist'
import 'js/admin/tools/photo_search'

// Public
import 'js/public/adopted_slideshow'
import 'js/public/alerts'
import 'js/public/autocomplete_address'
import 'js/public/blog_post'
import 'js/public/campaign_collection'
import 'js/public/enquiry'
import 'js/public/homepage'
import 'js/public/listing'
import 'js/public/listings'
import 'js/public/share_listing'
import 'js/public/report_listing'
import 'js/public/notifications'
import 'js/public/pet-hack-2020'
import 'js/public/slow-scroll'
import 'js/public/user'
import 'js/public/our-people'
import 'js/public/release-notes'
import 'js/public/feed-set-utm-tags'
import 'js/public/signup'
import 'js/public/release_notes'

// Uncomment to enable the christmas protocol
// import 'js/public/christmas-protocol'

// Unsorted
import 'js/unsorted/alert'
import 'js/unsorted/verification-setup'
import 'js/unsorted/global-conversations_unread_count'
import 'js/unsorted/search-listings'
import 'js/unsorted/init-autosize'

// Rescue Directory Map
import 'js/rescue_directory/map'

// Cloudinary
import 'js/cloudinary/responsive/init'

// Components
import 'js/lib/create-component'
// This code imports all the assets in the app/components directory
importAll(require.context('components', true, /component(.js|.sass)$/))

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log(`Hello World from Webpacker ${image}`)
// testFn()

function importAll(r) {
  r.keys().forEach(r)
}
